@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&family=Schoolbell&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
